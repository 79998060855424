/* Header CSS */

.header {
  height: 103px;
  position: fixed;
  top: -150px; /* Начальная позиция для выезда сверху */
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.8); /* Более тёмный фон для контраста */
  backdrop-filter: blur(10px); /* Blurring effect */
  -webkit-backdrop-filter: blur(10px); /* Для Safari */
  transition: top 1s ease-in-out; /* Плавное движение */
}

.header.animate-header {
  top: 0; /* Конечная позиция */
}

.header .container {
  max-width: 1800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px; /* Отступ для упорядочивания */
}

.header .logo {
  color: whitesmoke; /* Белый текст для видимости */
  font-size: 36px;
  font-weight: 700;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8); /* Тень для улучшения читаемости */
}

.header .nav-links {
  display: flex;
  gap: 20px;
  align-items: center; /* Выравнивание ссылок и кнопок на одном уровне */
}

.header .nav-links a {
  color: whitesmoke; /* Плавное изменение цвета */
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  font-family: "Cormorant Garamond", serif;
}

.header .nav-links button {
  color: whitesmoke;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  font-family: "Cormorant Garamond", serif;
  background: none;
  border: none;
  cursor: pointer;
}

.header .nav-links a:hover,
.header .nav-links button:hover {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Эффект свечения на hover */
}

/* Mobile Adjustments */
@media (max-width: 767px) {
  .header {
    height: 80px;
  }

  .header .logo {
    font-size: 28px; /* Уменьшаем шрифт для мобильных */
  }

  .header .nav-links button {
    display: none; /* Прячем кнопки для мобильных */
  }
  .header .nav-links a {
    font-size: 16px; /* Прячем кнопки для мобильных */
  }
}
