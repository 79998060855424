/* Verify Email container */
.verify-email-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.verify-email-container h2 {
  color: #CD5C32;
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Cormorant Garamond', serif;
}

.verify-email-container p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}

.verify-email-container button {
  padding: 10px 20px;
  background-color: #CD5C32;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  font-family: 'Cormorant Garamond', serif;
  cursor: pointer;
}

.verify-email-container button:hover {
  background-color: #a84427;
}

.verify-email-container a {
  display: block;
  margin-top: 15px;
  color: #CD5C32;
  font-size: 0.9rem;
  text-decoration: none;
}

.verify-email-container a:hover {
  text-decoration: underline;
}
