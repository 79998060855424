/* src/comps/login/Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.login-form {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-form h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
}

.login-form label {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #333333;
  margin-bottom: 5px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #CD5C32; /* Accent color */
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #b34727; /* Darker accent color */
}

.error-message {
  color: red;
  margin-bottom: 15px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.register-link {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.register-link a {
  color: #CD5C32;
  text-decoration: none;
}

.register-link a:hover {
  text-decoration: underline;
}