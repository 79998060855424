/* src/comps/dashboard/dashboard.css */

/* Container Styling */
.dashboard-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Title Styling */
.dashboard-title {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  animation: fadeIn 1s ease-in-out;
}

/* Section Titles */
.section-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-top: 30px;
  margin-bottom: 15px;
}

/* General Text */
.dashboard-text {
  font-size: 16px;
  color: #555;
}

/* Links */
.dashboard-link {
  color: #CD5C32;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.dashboard-link:hover {
  color: #b34727;
}

/* Input Fields */
.input-field {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #CD5C32;
  border-radius: 5px;
  font-size: 14px;
  color: #333333;
  background-color: #ffffff;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #b34727;
}

/* Buttons */
.button-save {
  background-color: #CD5C32;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  transition: background-color 0.3s ease, transform 0.2s;
}

.button-save:hover {
  background-color: #b34727;
  transform: translateY(-2px);
}

.button-link {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s;
  display: inline-block;
  margin-left: 10px;
}

.button-link:hover {
  background-color: #CD5C32;
  transform: translateY(-2px);
}

.button-toggle {
  background-color: #CD5C32;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s;
}

.button-toggle:hover {
  background-color: #b34727;
  transform: translateY(-2px);
}

/* Instruction Box */
.instruction-box {
  margin-top: 15px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #CD5C32;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  animation: fadeIn 0.5s ease-in-out;
}

/* Readonly Input */
.input-readonly {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #CD5C32;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  background-color: #f1f1f1;
  cursor: pointer;
  margin-top: 10px;
}

.input-readonly:focus {
  background-color: #e9e9e9;
  outline: none;
  border-color: #b34727;
}

/* Code Block Styling */
.code-block {
  background-color: #f9f9f9;
  border: 1px solid #CD5C32;
  border-radius: 5px;
  padding: 10px;
  font-family: monospace;
  font-size: 14px;
  color: #333;
  display: inline-block;
  width: 100%;
}

.code-input {
  background: none;
  border: none;
  width: 100%;
  color: #333;
  font-size: 14px;
  padding: 5px;
  outline: none;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .dashboard-container {
    padding: 15px;
    max-width: 100%;
  }

  .dashboard-title {
    font-size: 24px;
  }

  .section-title {
    font-size: 20px;
  }

  .input-field, .button-save, .button-link, .button-toggle, .input-readonly {
    width: 100%;
    margin-bottom: 10px;
  }

  .instruction-box {
    font-size: 13px;
  }
}
