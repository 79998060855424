/* Register.css */

.register-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Cormorant Garamond', serif;
  text-align: center;
}

.register-container h2 {
  color: #CD5C32;
  font-size: 2rem;
  margin-bottom: 20px;
}

.register-container .message {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #333;
}

.register-container label {
  display: block;
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  margin: 15px 0 5px;
  text-align: left;
}

.register-container input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 20px;
  outline: none;
  font-family: 'Cormorant Garamond', serif;
}

.register-container input:focus {
  border-color: #CD5C32;
  box-shadow: 0 0 5px rgba(205, 92, 50, 0.3);
}

.register-container button {
  width: 100%;
  padding: 12px;
  background-color: #CD5C32;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-container button:hover {
  background-color: #a84427;
}