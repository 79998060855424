.sc-clients {
  padding: 50px 20px;
  background-color: #ffffff;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.sc-clients h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  margin-bottom: 40px;
  color: #333333;
  position: relative;
}

.sc-clients h2::after {
  content: '';
  width: 60px;
  height: 4px;
  background-color: #CD5C32;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

.clients-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px; /* Increased gap between the cards */
}

.client-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 250px; /* Increased width */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
  transform: translateY(100px);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure content is spaced out */
}

.client-card.visible {
  transform: translateY(0);
  opacity: 1;
}

.client-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.client-card img {
  width: 100%;
  height: auto;
}

.client-card p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #333333;
  margin: 0; /* Removed margin to fit better */
  background: rgba(255, 255, 255, 0.8);
  padding: 10px 0;
  align-self: stretch; /* Ensure text spans the full width */
  text-align: center; /* Center the text */
}

@media (max-width: 991px) {
  .client-card {
    width: 180px; /* Adjusted width for smaller screens */
  }

  .sc-clients h2 {
    font-size: 24px;
  }

  .client-card p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .client-card {
    width: 150px; /* Adjusted width for even smaller screens */
  }

  .sc-clients h2 {
    font-size: 20px;
  }

  .client-card p {
    font-size: 14px;
  }
}
