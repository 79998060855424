.dialogues-container {
  display: flex;
  height: 100vh;
  background-color: #f5f5f5;
}

.dialogues-list {
  width: 30%;
  border-right: 1px solid #ddd;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.database-item {
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.3s ease, transform 0.5s ease;
}

.database-item:hover {
  background-color: #f1f1f1;
}

.database-item.selected {
  background-color: #cd5c32;
  color: #ffffff;
}

.delete-button {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.database-item:hover .delete-button {
  opacity: 1;
}

.slide-out {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.messages-container {
  width: 70%;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.messages {
  margin-top: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.message.user {
  background-color: #f1f1f1;
}

.message.assistant {
  background-color: #e7f7fe;
}

.timestamp {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 1.1em;
  color: #888888;
}

.send-message-form {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 10px 0 0;
}

.send-message-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
}

.send-message-form button {
  padding: 10px 20px;
  background-color: #cd5c32;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-message-form button:hover {
  background-color: #b34727;
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

h2::after {
  content: '';
  width: 60px;
  height: 4px;
  background-color: #cd5c32;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}