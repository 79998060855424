.sc-feedback {
  background-size: cover!important;
  background-repeat: no-repeat!important;
  background-position: center center!important;
  background: url(../../assets/images/feedback.png);
  min-height: 767px;
  padding-top: 184px;
  position: relative;
  text-align: center;
  z-index: 1;
  width: 100%;
  margin: auto;
}

.sc-feedback:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.sc-feedback h1 {
  font-weight: 700;
  color: #F8EADC;
  font-size: 48px;
  margin-bottom: 20px;
}

.sc-feedback .stars {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sc-feedback .stars button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.sc-feedback .stars .on .star {
  color: #FFD700;
}

.sc-feedback .stars .off .star {
  color: #E4E5E9;
}

.sc-feedback .form__group {
  margin-bottom: 20px;
}

.sc-feedback .form__input {
  padding: 10px;
  border: 2px solid #CD5C32;
  border-radius: 5px;
  width: 80%;
  max-width: 400px;
  font-size: 16px;
}

.sc-feedback .submit__button {
  background-color: #CD5C32;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .sc-feedback {
    min-height: auto;
    padding-top: 167px;
    padding-bottom: 143px;
  }
  .sc-feedback h1 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .sc-feedback {
    padding-top: 135px;
    padding-bottom: 92px;
    background: url(../../assets/images/feedback.png);
  }
  .sc-feedback h1 {
    font-size: 28px;
  }
}
