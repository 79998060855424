/* Основной контейнер */
.products-grid-container {
  max-width: 1200px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff; /* Белый фон */
  font-family: "Montserrat", sans-serif;
}

/* Заголовок */
.products-title {
  text-align: center;
  font-size: 32px;
  color: #cd5c32; /* Оранжевый акцент */
  margin-bottom: 30px;
  font-weight: bold;
}

/* Сетка продуктов */
.products-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Расстояние между блоками */
  justify-content: space-between;
}

/* Карточка продукта */
.product-card {
  background-color: white; /* Белый фон */
  border: 1px solid #cd5c32; /* Оранжевая рамка */
  border-radius: 10px;
  padding: 20px;
  width: calc(33.333% - 20px); /* Три карточки в строке */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Легкая тень */
  transition: transform 0.3s ease-in-out;
}

/* Эффект наведения */
.product-card:hover {
  transform: translateY(-10px); /* Подъем карточки */
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

/* Название продукта */
.product-name {
  font-size: 20px;
  font-weight: bold;
  color: #cd5c32; /* Оранжевый акцент */
  margin-bottom: 15px;
}

/* Особенности продукта */
.product-features {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 15px;
}

.product-features li {
  font-size: 16px;
  color: #333333; /* Темный текст */
  margin-bottom: 8px;
}

/* Стоимость продукта */
.product-price {
  font-size: 18px;
  font-weight: bold;
  color: #cd5c32; /* Оранжевый акцент */
  text-align: center;
}

/* Ссылка на продукт */
.product-link {
  display: inline-block;
  text-align: center;
  background-color: #cd5c32; /* Оранжевый фон */
  color: white; /* Белый текст */
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.product-link:hover {
  background-color: #a04626; /* Более тёмный оранжевый */
}

/* Адаптивность */
@media (max-width: 991px) {
  .product-card {
    width: calc(50% - 20px); /* Две карточки в строке */
  }

  .products-title {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .product-card {
    width: 100%; /* Одна карточка в строке */
  }

  .products-title {
    font-size: 20px;
  }

  .product-name {
    font-size: 18px;
  }

  .product-price {
    font-size: 16px;
  }

  .product-link {
    font-size: 14px;
    padding: 8px 12px;
  }
}