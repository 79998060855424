#openA {
  z-index: 10000;
}

.sc-form {
  display: flex;
  flex-direction: column;
  width: 336px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100000;
  display: none;
}

.form__send__top__open {
  padding: 27px 60px 10px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: #CD5C32;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 316px;
  z-index: 10000;
}

.form__send__top__open .logo {
  font-size: 20px;
  color: #F8EADC;
  text-decoration: none;
  font-family: "Cormorant Garamond", serif;
  vertical-align: center;
  z-index: 10000;
}

.sc-form .logo {
  font-size: 20px;
  color: #F8EADC;
  text-decoration: none;
  vertical-align: center;
}

.sc-form .title {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 20px 0 25px;
  background-color: #fff;
}

.sc-form h2 {
  font-weight: 700;
  font-size: 47px;
  margin-bottom: 0;
}

.sc-form .test__form {
  display: block;
  margin: 30px auto;
  width: 100%;
  max-width: 794px;
}

.sc-form .form__box {
  display: inline-block;
  width: 100%;
  position: relative;
}

.sc-form .form_send__top {
  padding: 27px 60px 10px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: #D85D2E;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 316px;
  cursor: pointer;
}

.sc-form .form__body {
  background-color: white;
  height: 400px;
  overflow-y: scroll;
  background-image: url('../../assets/images/66.jpg');
  background-size: cover;
  background-position: center top;
}

.sc-form .form__bottom {
  border-top: #1f6f65 2px solid;
  max-height: 150px;
  height: 65px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: #222222;
}

.sc-form .select_wrap {
  width: 100%;
  margin: 15px auto;
  position: relative;
  user-select: none;
  font-size: 25px;
  text-align: left;
  font-weight: 700;
}

.sc-form .select_wrap ul {
  list-style: none;
  padding-left: 0;
}

.sc-form .select_wrap p {
  margin-bottom: 0;
}

.sc-form .select_wrap .default_option {
  background: #fff;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.sc-form .select_wrap .default_option li {
  padding: 10px 20px;
}

.sc-form .select_wrap .default_option:before {
  content: "";
  position: absolute;
  top: 18px;
  right: 18px;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-color: transparent transparent #555555 #555555;
  transform: rotate(-45deg);
}

.sc-form .select_wrap .select_ul {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  display: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.233);
}

.sc-form .select_wrap .select_ul li {
  padding: 10px 20px;
  cursor: pointer;
}

.sc-form .select_wrap .select_ul li:first-child:hover {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.sc-form .select_wrap .select_ul li:last-child:hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sc-form .select_wrap .select_ul li:hover {
  background: #fff4dd;
}

.sc-form .select_wrap .option {
  display: flex;
  align-items: center;
}

.sc-form .select_wrap .option .icon {
  width: 32px;
  height: 32px;
  margin-right: 15px;
}

.sc-form .select_wrap .option .icon img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}

.sc-form .select_wrap.active .select_ul {
  display: block;
}

.sc-form .select_wrap.active .default_option:before {
  top: 25px;
  transform: rotate(-225deg);
}

.sc-form .field {
  width: 90%;
  display: flex;
  align-items: center;
  margin: auto;
  background-color: #222222;
}

.sc-form .field input {
  width: 100%;
  height: 48px;
  font-family: "Cormorant Garamond", serif;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: 700;
  background-color: #222222;
  color: white;
}

button img {
  height: 48px;
}

.sc-form .status {
  display: inline-block;
  color: #000;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  font-weight: 700;
  min-width: 189px;
  font-size: 20px;
  margin-bottom: 9px;
}

/* Responsive styles */
@media (max-width: 991px) {
  .sc-form h2 {
    font-size: 20px;
  }
  .sc-form .form__head {
    padding-left: 45px;
    padding-right: 45px;
  }
  .sc-form .form__body {
    min-height: 400px;
    overflow-y: auto;
  }
}

@media (max-width: 767px) {
  .sc-form h2 {
    font-size: 23px;
  }
  .sc-form .status {
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sc-form .form__head {
    min-height: auto;
    padding-left: 30px;
    padding-right: 30px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .sc-form .form__body {
    min-height: 289px;
    overflow-y: auto;
  }
  .sc-form .form__bottom {
    min-height: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  .sc-form .field input {
    height: 48px;
    font-size: 20px;
  }
  button img {
    height: 48px;
  }
  .sc-form .select_wrap {
    font-size: 20px;
  }
  .sc-form .select_wrap .option .icon {
    width: 24px;
    height: 24px;
  }
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}

/* Message styles */
.sc-form .message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  position: relative;
  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
}

/* User's message (right-aligned) */
.sc-form .message.right {
  background-color: #f0f0f0;
  margin-left: auto;
  margin-right: 5%;
  text-align: right;
}

/* Assistant's message (left-aligned) */
.sc-form .message.left {
  background-color: #f0f0f0;
  margin-left: 5%;
  text-align: left;
}

/* Timestamp styling */
.sc-form .message.left .timestamp {
  position: absolute;
  bottom: 1px;
  right: 10px;
  font-size: 0.75em;
  color: #888888;
}
.sc-form .message.right .timestamp {
  position: absolute;
  bottom: 1px;
  left: 10px;
  font-size: 0.75em;
  color: #888888;
}
