.sc-info {
  padding-bottom: 30px;
}

.sc-info .info__title {
  font-family: "Montserrat";
  font-size: 27px;
  padding-top: 30px;
}

.sc-info .img__left {
  padding: 54px 0;
  display: flex;
  align-items: end;
  width: 100%;
  opacity: 0;
  transform: translateX(100px);
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.sc-info .img__right {
  padding: 54px 0;
  display: flex;
  align-items: end;
  width: 100%;
  opacity: 0;
  transform: translateX(-100px);
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}
.sc-info .info__wrap.visible {
  opacity: 1;
  transform: translateX(0);
}

.sc-info .info__wrap.img__left .img__box {
  order: 1;
}


.sc-info .info__wrap.img__left .info__text {
  order: 2;
}

.sc-info .info__wrap.img__right .img__box {
  order: 2;
}



.sc-info .info__wrap.img__right .info__text {
  order: 1;
}

.sc-info .img__box {
  width: 50%;
}

.sc-info .img__box img {
  max-width: 100%;
}

.sc-info .info__text {
  width: 50%;
  padding-left: 40px;
  padding-right: 40px;
}

.sc-info .info__text h4 {
  font-weight: 700;
  font-size: 60px;
  margin-bottom: 24px;
  line-height: 89%;
}

.sc-info .info__text p {
  font-size: 18px;
  font-family: "Montserrat";
}

.sc-info .info__text ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sc-info .info__text ul li {
  font-size: 18px;
  font-family: "Montserrat";
  margin-bottom: 10px;
}

.sc-info .info__text ul li strong {
  color: #CD5C32;
}

@media (max-width: 991px) {
  .sc-info .info__title {
    font-size: 17px;
    padding-top: 0;
  }
  .sc-info .info__wrap {
    padding: 24px 0;
  }
  .sc-info .info__text h4 {
    font-size: 28px;
  }
  .sc-info .info__text p,
  .sc-info .info__text ul li {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .sc-info .img__box img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
    object-position: center center;
  }
  .sc-info .info__text {
    padding-left: 0;
    padding-right: 10px;
  }
  .sc-info .info__wrap.img__left .info__text {
    padding-left: 20px;
  }
  .sc-info .info__wrap.img__right .info__text {
    padding-right: 20px;
  }
}

@media (max-width: 576px) {
  .sc-info .info__wrap {
    align-items: normal;
  }
  .sc-info .img__box {
    height: auto;
    width: calc(100% + 10px);
  }
  .sc-info .info__wrap.img__left .img__box {
    margin-left: -10px;
  }
  .sc-info .info__wrap.img__right .img__box {
    margin-right: -10px;
  }
  .sc-info .info__title {
    font-size: 15px;
  }
  .sc-info .info__text h4 {
    font-size: 21px;
  }
  .sc-info .info__text {
    width: 98%;
  }
  .sc-info .info__text p,
  .sc-info .info__text ul li {
    font-size: 14px;
  }
}
