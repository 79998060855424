.footer{
    padding-top: 80px;
    padding-bottom: 98px;
    text-align: center;
}
.footer h4{
    font-weight: 700;
    font-size: 72px;
    margin-bottom: 45px;
}
.footer .contact__form{
    max-width: 392px;
    display: inline-block;
    width: 100%;
}
.footer .contact__form .input__field{
    width: 100%;
    height: 30px;
    outline: none;
    border: none;
    border-bottom: 2px solid #1E5449;
    font-size: 10px;
    font-family: 'Montserrat';
    font-size: 16px;
    margin-bottom: 23px;
}
.footer .submit{
    display: inline-block;
    font-family: 'Montserrat';
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    background-color: #C0C0C0;
    padding: 16px 26px;
    outline: none;
    border: none;
    text-align: center;
    min-width: 280px;
    cursor: pointer;
    transition: 0.3s;
}
.footer .submit:hover{
    background-color: #000;
}
.footer__top{
    padding-top: 120px;
    padding-bottom: 120px;
}
.footer__bottom{
    padding-top: 50px;
    padding-bottom: 50px;
    border-top: 1px solid rgba(42, 41, 40, 0.11);
}
.footer .social__box{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
}
.footer .social{
   display: flex;
   justify-content: center;
    width: 100%;
    margin-bottom: 26px;
}
.footer .social a{
    width: 53px;
    height: 53px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    border: solid 1px #000;
    transition: 0.2s;
}
.footer .social a:hover{
    background-color: rgba(0, 0, 0, 0.199);
    border-color: transparent;
}
.footer .social__box p{
    font-size: 16px;
    color: #000;
    font-family: 'Montserrat';
}

@media (max-width: 767px) {
    .footer{
        padding-top: 0;
        padding-bottom: 0;
    }
    .footer h4{
        font-size: 35px;
    }
}
.social img{
    width: 80%; /* Изображение заполняет всю ширину родительского элемента */
  height: 80%; /* Изображение заполняет всю высоту родительского элемента */
  object-fit: cover;
}