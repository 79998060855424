.sc-testing {
  background-color: #222222;
}
.sc-testing .title {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 25px;
  background-color: #fff;
}
.sc-testing h2 {
  font-weight: 700;
  font-size: 47px;
  margin-bottom: 0;
}
.sc-testing .test__form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 794px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.sc-testing .form__box {
  display: inline-block;
  width: 100%;
  position: relative;
}
.sc-testing .form__head {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 27px;
  padding-bottom: 10px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: #D85D2E;
  min-height: 150px;
  text-align: center;
}
.sc-testing .form__body {
  background-color: white;
  height: 400px;
  overflow-y: auto;
}

.sc-testing .form__bottom {
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  background-color: #1F6F65;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 43px;
  padding-bottom: 44px;
  min-height: 150px;
}
.sc-testing .select_wrap {
  width: 100%;
  margin: 15px auto;
  position: relative;
  user-select: none;
  font-size: 25px;
  text-align: left;
  font-weight: 700;
}
.sc-testing .select_wrap ul {
  list-style: none;
  padding-left: 0;
}
.sc-testing .select_wrap p {
  margin-bottom: 0;
}
.sc-testing .select_wrap .default_option {
  background: #fff;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}
.sc-testing .select_wrap .default_option li {
  padding: 10px 20px;
}
.sc-testing .select_wrap .default_option:before {
  content: "";
  position: absolute;
  top: 18px;
  right: 18px;
  width: 6px;
  height: 6px;
  border: 2px solid;
  border-color: transparent transparent #555555 #555555;
  transform: rotate(-45deg);
}
.sc-testing .select_wrap .select_ul {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  display: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.233);
}
.sc-testing .select_wrap .select_ul li {
  padding: 10px 20px;
  cursor: pointer;
}
.sc-testing .select_wrap .select_ul li:first-child:hover {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.sc-testing .select_wrap .select_ul li:last-child:hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sc-testing .select_wrap .select_ul li:hover {
  background: #fff4dd;
}
.sc-testing .select_wrap .option {
  display: flex;
  align-items: center;
}
.sc-testing .select_wrap .option .icon {
  width: 32px;
  height: 32px;
  margin-right: 15px;
}
.sc-testing .select_wrap .option .icon img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}
.sc-testing .select_wrap.active .select_ul {
  display: block;
}
.sc-testing .select_wrap.active .default_option:before {
  top: 25px;
  transform: rotate(-225deg);
}
.sc-testing .field {
  width: 100%;
  display: flex;
  align-items: center;
}
.sc-testing .field input {
  width: 100%;
  height: 63px;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Cormorant Garamond", serif;
  outline: none;
  border: none;
  font-size: 30px;
  font-weight: 700;
}
button img {
  height: 63px;
}
.sc-testing .status {
  display: inline-block;
  color: #000;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
  font-weight: 700;
  min-width: 189px;
  font-size: 30px;
  margin-bottom: 9px;
}

@media (max-width: 991px) {
  .sc-testing h2 {
    font-size: 37px;
  }
  .sc-testing .form__head {
    padding-left: 45px;
    padding-right: 45px;
  }
  .sc-testing .form__bottom {
    padding-left: 45px;
    padding-right: 45px;
  }
  .sc-testing .form__body {
    min-height: 400px;
    overflow-y: auto;
  }
}

@media (max-width: 767px) {
  .sc-testing h2 {
    font-size: 23px;
  }
  .sc-testing .status {
    font-size: 24px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sc-testing .form__head {
    min-height: auto;
    padding-left: 30px;
    padding-right: 30px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }
  .sc-testing .form__body {
    min-height: 289px;
    overflow-y: auto;
  }
  .sc-testing .form__bottom {
    min-height: auto;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .sc-testing .field input {
    height: 48px;
    font-size: 20px;
  }
  button img {
    height: 48px;
  }
  .sc-testing .select_wrap {
    font-size: 20px;
  }
  .sc-testing .select_wrap .option .icon {
    width: 24px;
    height: 24px;
  }
}
button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}

/* Message styles */
.sc-testing .message {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 45%;
  position: relative;
  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
}

/* Right-aligned (user) message */
.sc-testing .message.right {
  background-color: #f0f0f0;
  margin-left: auto;
  margin-right: 5%;
  text-align: right;
}

/* Left-aligned (assistant) message */
.sc-testing .message.left {
  background-color: #f0f0f0;
  margin-left: 5%;
  text-align: left;
}

/* Timestamp styling */
.sc-testing .message.left .timestamp {
  position: absolute;
  bottom: 1px;
  right: 10px;
  font-size: 0.75em;
  color: #888888;
}

.sc-testing .message.right .timestamp {
  position: absolute;
  bottom: 1px;
  left: 10px;
  font-size: 0.75em;
  color: #888888;
}