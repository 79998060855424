.sc-write-us{
    background-image: url(../../assets//images/country.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 801px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding-top: 100px;
    padding-bottom: 100px;
}
.sc-write-us:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: -1;
}
.sc-write-us .write__info{
    max-width: 1000px;
    margin: auto;
    text-align: center;
}
.sc-write-us h2{
    font-size: 60px;
    font-weight: 400;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.507);
    padding: 10px 25px;
}
.sc-write-us .primary--btn{
    display: inline-block;
    font-family: 'Montserrat';
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.507);
    padding: 16px 26px;
    outline: none;
    border: none;
    text-align: center;
    margin-top: 27px;
    transition: 0.3s;
}
.sc-write-us .primary--btn:hover{
    background-color: #000;
}

@media (max-width: 767px){
    .sc-write-us {
        min-height: 534px;
        background-image: url(../../assets/images/country-mob.webp);
    }
    .sc-write-us h2 {
        font-size: 38px;
    }
    .sc-write-us .primary--btn{
        width: 100%;
    }
}