/* OutcomeSection.css */

.outcome-section {
  background-color: #222222;
  color: #F8EADC;
  padding: 50px 20px;
  text-align: center;
}

#end {
  color: #F8EADC;
}

.outcome-section .container {
  max-width: 900px;
  margin: 0 auto;
  animation: fadeIn 1.5s ease-in-out;
}

.outcome-section h2 {
  font-size: 32px;
  margin-bottom: 20px;
  font-family: "Cormorant Garamond", serif;
}

.outcome-section ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.outcome-section ul li {
  text-align: left;
  font-size: 25px;
  margin-bottom: 15px;
  animation: slideIn 1.5s ease-in-out;
}

.outcome-section ul li strong {
  color: #CD5C32;
}

.outcome-section p {
  font-size: 40px;
  margin-top: 20px;
  font-family: "Cormorant Garamond", serif;
}

.performance-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  font-size: 18px;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Cormorant Garamond", serif;
}

.performance-table thead th {
  background-color: #CD5C32;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  font-size: 20px;
}

.performance-table tbody td {
  padding: 15px;
  text-align: left;
  color: #CD5C32;
  font-size: 18px;
}

.table-title {
  color: #F8EADC;
}

.performance-table tbody tr {
  background-color: #ffffff;
}

.performance-table tbody tr:hover {
  background-color: #f2f2f2;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideIn {
  0% { transform: translateX(-50px); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInUp {
  0% { transform: translateY(50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}
