/* Общие стили */
.popup-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  max-width: 320px;
  background-color: #20232a; /* Темный фон, соответствующий гамме изображения */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1747340572034758;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  color: #f5f5f5; /* Светлый текст */
  opacity: 0;
  transform: translateY(50px);
  animation: slideIn 0.5s forwards;

}

/* Контент всплывающего окна */
.popup-content {
  text-align: center;
}

.popup-link {
  color: #CD5C32; /* Акцентный голубой цвет */
  text-decoration: none;
  font-weight: bold;
}

.popup-link:hover {
  text-decoration: underline;
}

/* Контейнер кнопки */
.popup-button-container {
  margin-top: 15px;
  text-align: center;
}

/* Кнопка "Согласиться" */
.popup-button {
  background-color: #CD5C32; /* Голубой акцентный цвет */
  color: #20232a; /* Темный текст на кнопке */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-button:hover {
  background-color: #CD5C32; /* Темнее при наведении */
}

/* Анимация появления */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}