/* src/comps/adminPanel/adminPanel.css */

/* Основные стили для админ-панели */
.admin-panel {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  background-color: #FFFFFF; /* Белый фон */
  color: #000000; /* Черный текст */
  min-height: 100vh;
}

/* Заголовок админ-панели */
.admin-panel h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #000000; /* Черный цвет */
  font-size: 36px;
  font-weight: bold;
}

/* Стили для таблицы данных */
.data-table-container {
  margin-bottom: 60px;
}

.data-table-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #000000; /* Черный цвет */
  font-size: 28px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  background-color: #FFFFFF; /* Белый фон таблицы */
}

.data-table th,
.data-table td {
  border: 1px solid #000000; /* Черная граница */
  padding: 15px;
  text-align: left;
}

.data-table th {
  background-color: #FFFFFF; /* Белый фон для заголовков */
  font-weight: bold;
  color: #000000; /* Черный цвет текста */
}

.data-table tr:nth-child(even) {
  background-color: #F0F0F0; /* Светло-серый фон для четных строк */
}

.data-table tr:hover {
  background-color: #E0E0E0; /* Более темный серый при наведении */
}

.data-table input {
  width: 100%;
  padding: 10px;
  border: 1px solid #000000; /* Черная рамка */
  border-radius: 5px;
  font-size: 14px;
  background-color: #FFFFFF; /* Белый фон */
  color: #000000; /* Черный текст */
}

.data-table input:disabled {
  background-color: #E0E0E0;
  cursor: not-allowed;
}

/* Стили для просмотра папок и баз данных */
.asst-folders-view {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 20px;
}

.folders-list,
.databases-list,
.messages-view {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #FFFFFF; /* Белый фон */
  border: 2px solid #000000; /* Черная рамка */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Легкая тень */
}

.folders-list h2,
.databases-list h2,
.messages-view h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #000000; /* Черный цвет */
  font-size: 24px;
}

/* Стили для списков */
.folders-list ul,
.databases-list ul {
  list-style: none;
  padding: 0;
}

.folders-list li,
.databases-list li {
  margin-bottom: 15px;
}

.folders-list button,
.databases-list button {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  background-color: #000000; /* Черный фон кнопок */
  color: #FFFFFF; /* Белый текст */
  border: none;
  border-radius: 5px; /* Закругленные углы */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.folders-list button:hover,
.databases-list button:hover {
  background-color: #333333; /* Более светлый черный при наведении */
}

.folders-list button.active,
.databases-list button.active {
  background-color: #333333; /* Более светлый черный для активных кнопок */
}

.messages-view ul {
  list-style: none;
  padding: 0;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #000000; /* Черная рамка */
  border-radius: 5px;
  padding: 10px;
  background-color: #FFFFFF; /* Белый фон */
}

.messages-view li {
  margin-bottom: 15px;
  padding: 15px;
  background-color: #F0F0F0; /* Светло-серый фон для сообщений */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.messages-view li strong {
  display: block;
  margin-bottom: 5px;
  color: #000000; /* Черный цвет */
  font-size: 16px;
}

.messages-view li span {
  color: #000000; /* Черный текст */
  font-size: 14px;
}

/* Анимации */
button {
  transition: background-color 0.3s ease;
}

button:focus {
  outline: none;
}

/* Стили для полей ввода */
.data-input {
  border: 1px solid #000000; /* Черная рамка */
  border-radius: 5px;
  padding: 8px;
  font-size: 14px;
  background-color: #FFFFFF; /* Белый фон */
  color: #000000; /* Черный текст */
}

/* Мобильная адаптивность */
@media (max-width: 768px) {
  .asst-folders-view {
    flex-direction: column;
  }

  .data-table th,
  .data-table td {
    padding: 12px;
  }

  .data-table-container h2,
  .folders-list h2,
  .databases-list h2,
  .messages-view h2 {
    font-size: 20px;
  }

  .folders-list button,
  .databases-list button {
    font-size: 16px;
    padding: 12px;
  }

  .data-table input {
    padding: 8px;
    font-size: 12px;
  }
}
